import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "_heading-2" }
const _hoisted_2 = { class: "px-4 pb-5" }
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: _normalizeClass(_ctx.$style.modal),
    visible: _ctx.isShown,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShown) = $event)),
    modal: true,
    draggable: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_Toolbar, { class: "p-4" }, {
          left: _withCtx(() => [
            _createElementVNode("p", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('available_options')), 1)
            ])
          ]),
          right: _withCtx(() => [
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              class: "p-button-outlined p-button-gray p-button-rounded p-button-only-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "close" })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.licence_plates.length)
            ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: _normalizeClass({
                [_ctx.$style.modalImgBox]: true,
                [_ctx.$style.modalImgBoxAdd]: true
              })
              }, [
                _createElementVNode("label", null, [
                  _createElementVNode("input", {
                    type: "file",
                    accept: "image/png, image/jpeg",
                    onChange: _cache[1] || (_cache[1] = (e) => _ctx.uploadFile(e))
                  }, null, 32),
                  _createVNode(_component_Icon, {
                    name: "plus",
                    className: {
                    [_ctx.$style.modalIcon]: true,
                    [_ctx.$style.modalIconAdd]: true
                  }
                  }, null, 8, ["className"])
                ])
              ], 2)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licence_plates, (plate) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-3",
                key: plate.id,
                onClick: () => _ctx.$emit('onPlateSet', plate)
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.$style.modalImgBox)
                }, [
                  _createElementVNode("img", {
                    src: plate.url,
                    class: _normalizeClass(_ctx.$style.modalImg),
                    alt: ""
                  }, null, 10, _hoisted_6),
                  _createVNode(_component_Icon, {
                    name: "plus",
                    className: _ctx.$style.modalIcon
                  }, null, 8, ["className"])
                ], 2)
              ], 8, _hoisted_5))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "visible"]))
}