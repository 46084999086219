
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';

import { Icon, Empty } from '../../components/ui';

import referenceCutting from '../../assets/img/reference_cutting.jpg';
import referenceBlur from '../../assets/img/reference_cutting.jpg';
import referencePlate from '../../assets/img/reference_cutting.jpg';
import { useStores } from '@/store/Stores';
import { AccountSettings } from '@/store/modules/AccountStore';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';

import VehicleLicensePlatesBasicDialog from './VehicleLicensePlatesBasicDialog/VehicleLicensePlatesBasicDialog.vue';

export default {
  components: {
    Icon,
    Dropdown,
    InputSwitch,
    Button,
    Dialog,
    Toolbar,
    Empty,
    VehicleLicensePlatesBasicDialog
  },
  setup() {
    const { accountStore, accountStoreState, settingsStore, settingsStoreState, roomComponentsStore, roomComponentsStoreState } = useStores();

    roomComponentsStore.init();

    const isShow = ref();
    const isPlateSelectorShown = ref();
    const helper = ref();
    const { t } = useI18n();
    const toast = useToast();
    const imagesListKey = ref();

    const settings = computed(() => accountStoreState.data.settings || ({} as any as AccountSettings));

    const previewRoom = computed(() => roomComponentsStoreState.rooms.find(({ id }) => id === settings.value.room_id) || {});
    const previewBG = computed(() => settingsStoreState.bg.data.find(({ id }) => id === settings.value.background_id) || {});
    const previewLogo = computed(() => settingsStoreState.logos.data.find(({ id }) => id === settings.value.logo_id) || {});
    const previewBanenr = computed(() => settingsStoreState.banners.data.find(({ id }) => id === settings.value.banner_id) || {});

    const helperImage = {
      cutting: referenceCutting,
      blur: referenceBlur,
      plate: referencePlate
    };

    const optionsBG = computed(() => [
      { value: 'Base', label: t('settings_page.base') },
      { value: 'Photo Box', label: t('settings_page.photo_box') }
    ]);

    const setRoom = (room: RoomComponent) => {
      settings.value.room_id = room.id;
      isShow.value = false;
    };

    function removeImage(type) {
      switch (type) {
        case 'logos':
          settings.value.logo_id = null;
          break;
        case 'banners':
          settings.value.banner_id = null;
          break;
      }
    }

    function handlePlateSelected(plate: any) {
      accountStore.dispatch(async (state) => {
        state.data.settings.licence_plate_id = plate.id;
        state.data.settings.licence_plate = plate;
      });
      isPlateSelectorShown.value = false;
    }

    function openModal(content) {
      isShow.value = true;
      imagesListKey.value = content;
    }

    function submit() {
      const body = {
        name: accountStoreState.data.name,
        email: accountStoreState.data.email,
        phone: accountStoreState.data.phone,
        default_crop: accountStoreState.data.settings.default_crop,
        background_type: accountStoreState.data.settings.background_type,
        licence_plate_id: accountStoreState.data.settings.licence_plate_id,
        preset_id: accountStoreState.data.settings.preset_id,
        cutting: accountStoreState.data.settings.cutting,
        room_id: accountStoreState.data.settings.room_id,
        plate_blur: accountStoreState.data.settings.plate_blur,
        plate_image: accountStoreState.data.settings.plate_image,
        polishing: accountStoreState.data.settings.polishing,
        rim_polishing: accountStoreState.data.settings.rim_polishing,
        reflective_floor: accountStoreState.data.settings.reflective_floor
      };

      accountStore.saveChanges(body, toast, t);
    }

    function handlingRelatedSwitches(param) {
      switch (param) {
        case 'blur':
          settings.value.plate_image = false;
          break;
        case 'image':
          settings.value.plate_blur = false;
          break;
      }
    }

    return {
      accountStore,
      accountStoreState,
      settingsStoreState,
      isShow,
      helper,
      imagesListKey,
      optionsBG,
      previewRoom,
      previewBG,
      previewLogo,
      previewBanenr,
      settings,
      roomComponentsStoreState,
      setRoom,
      submit,
      openModal,
      handlingRelatedSwitches,
      removeImage,
      helperImage,
      isPlateSelectorShown,
      handlePlateSelected
    };
  }
};
